.contact__container {
	grid-template-columns: repeat(2, max-content);
	justify-content: center;
	column-gap: 6rem;
	padding-bottom: 3rem;
}

.contact__title {
	text-align: center;
	font-size: var(--h3-font-size);
	font-weight: var(--font-medium);
	margin-bottom: var(--mb-1-5);
}

.contact__info {
	display: grid;
	row-gap: 1rem;
	grid-template-columns: 300px;
}

.contact__card {
	background-color: var(--container-color);
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 1rem;
	border-radius: 0.75rem;
	text-align: center;
}

.contact__card-icon {
	font-size: 2rem;
	color: var(--title-color);
	margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data {
	font-size: var(--small-font-size);
}

.contact__card-title {
	font-weight: var(--font-medium);
}

.contact__card-data {
	display: block;
	margin-bottom: var(--mb-0-75);
}

.contact__button {
	color: var(--text-color);
	font-size: var(--small-font-size);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	column-gap: 0.25rem;
}

.contact__button-icon {
	font-size: 1rem;
	transition: 0.3s;
}

.contact__button:hover .contact__button-icon {
	transform: translateX(0.25rem);
}

.contact__form {
	width: 360px;
}

.contact__form-div {
	position: relative;
	margin-bottom: var(--mb-2);
	height: 4rem;
}

.contact__form-input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 2px solid rgba(0, 0, 0, 0.3);
	background: none;
	color: var(--text-color);
	outline: none;
	border-radius: 0.75rem;
	padding: 1.5rem;
	z-index: 1;
}

.contact__form-tag {
	position: absolute;
	top: -0.75rem;
	left: 1.25rem;
	font-size: var(--smaller-font-size);
	padding: 0.25rem;
	background-color: var(--body-color);
	z-index: 10;
}

.contact__form-area {
	height: 6rem;
}

.contact__form-area textarea {
	resize: none;
}

/* Breakpoints */
/* For large devices */
@media screen and (max-width: 992px) {
	.contact__container {
		column-gap: 3rem;
	}
}

/* For medium devices */
@media screen and (max-width: 768px) {
	.contact__container {
		grid-template-columns: 1fr;
		row-gap: 3rem;
	}

	.contact__info {
		justify-content: center;
	}

	.contact__form {
		margin: 0 auto;
	}
}

@media screen and (max-width: 576px) {
	.contact__info {
		grid-template-columns: 1fr;
	}

	.contact__form {
		width: 100%;
	}
}

/* Toast styles */

.ToastViewport {
	--viewport-padding: 25px;
	position: fixed;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	padding: var(--viewport-padding);
	gap: 10px;
	width: 390px;
	max-width: 100vw;
	margin: 0;
	list-style: none;
	z-index: 2147483647;
	outline: none;
}

.ToastRoot {
	background-color: white;
	border-radius: 6px;
	box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 1px 2px -1.5px;
	padding: 15px;
	display: grid;
	grid-template-areas: "title action" "description action";
	grid-template-columns: auto max-content;
	column-gap: 15px;
	align-items: center;
}
.ToastRoot[data-state="open"] {
	animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state="closed"] {
	animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe="move"] {
	transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe="cancel"] {
	transform: translateX(0);
	transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe="end"] {
	animation: swipeOut 100ms ease-out;
}

@keyframes hide {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes slideIn {
	from {
		transform: translateX(calc(100% + var(--viewport-padding)));
	}
	to {
		transform: translateX(0);
	}
}

@keyframes swipeOut {
	from {
		transform: translateX(var(--radix-toast-swipe-end-x));
	}
	to {
		transform: translateX(calc(100% + var(--viewport-padding)));
	}
}

.ToastTitle {
	grid-area: title;
	margin-bottom: 5px;
	font-weight: 500;
	color: var(--slate12);
	font-size: 15px;
}

.ToastDescription {
	grid-area: description;
	margin: 0;
	color: var(--slate11);
	font-size: 13px;
	line-height: 1.3;
}

.ToastAction {
	grid-area: action;
}
